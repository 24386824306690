var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "goal-form pa-2", attrs: { elevation: 0, light: false } },
    [
      _c(
        "v-form",
        {
          ref: "form",
          attrs: { "lazy-validation": "" },
          model: {
            value: _vm.valid,
            callback: function($$v) {
              _vm.valid = $$v
            },
            expression: "valid"
          }
        },
        [
          _c("input", {
            attrs: { type: "hidden" },
            domProps: { value: _vm.form.id }
          }),
          _c(
            "v-layout",
            { attrs: { row: "", wrap: "" } },
            [
              _c("v-flex", { attrs: { xs12: "" } }, [
                _c(
                  "h4",
                  { staticClass: "starling-h4 StarlingDarkGrey--text" },
                  [
                    _c("custom-icon", { attrs: { name: "goal" } }),
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          _vm.$t("app.tools.goal_setter.fields.title.label")
                        ) +
                        "\n        "
                    )
                  ],
                  1
                )
              ]),
              _c(
                "v-flex",
                { attrs: { xs12: "", "mt-2": "" } },
                [
                  _c("v-textarea", {
                    staticClass: "goal-title",
                    attrs: {
                      outline: "",
                      counter: 255,
                      rules: _vm.rules.title,
                      placeholder: _vm.$t(
                        "app.tools.goal_setter.fields.title.placeholder"
                      ),
                      rows: "1",
                      "auto-grow": ""
                    },
                    model: {
                      value: _vm.form.title,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "title", $$v)
                      },
                      expression: "form.title"
                    }
                  })
                ],
                1
              ),
              _vm.showTasks
                ? [
                    _c("v-flex", { attrs: { xs12: "", "mb-2": "" } }, [
                      _c("h4", {
                        staticClass: "starling-h4 StarlingDarkGrey--text",
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.$t("app.tools.goal_setter.fields.task.title")
                          )
                        }
                      })
                    ]),
                    _vm._l(_vm.form.tasks, function(task, index) {
                      return _c(
                        "v-flex",
                        { key: index, attrs: { xs12: "" } },
                        [
                          _c(
                            "v-layout",
                            { attrs: { row: "", wrap: "", "mb-1": "" } },
                            [
                              _c(
                                "v-flex",
                                { attrs: { shrink: "" } },
                                [
                                  index < 4 &&
                                  index === _vm.form.tasks.length - 1
                                    ? _c(
                                        "v-btn",
                                        {
                                          staticClass: "add-task ma-0 mr-1",
                                          attrs: { icon: "", outline: "" },
                                          on: { click: _vm.onAddTask }
                                        },
                                        [_c("v-icon", [_vm._v("add")])],
                                        1
                                      )
                                    : index < _vm.form.tasks.length
                                    ? _c(
                                        "v-btn",
                                        {
                                          staticClass: "remove-task ma-0 mr-1",
                                          attrs: { icon: "" },
                                          on: {
                                            click: function($event) {
                                              return _vm.onRemoveTask(index)
                                            }
                                          }
                                        },
                                        [_c("v-icon", [_vm._v("remove")])],
                                        1
                                      )
                                    : _vm._e()
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                { attrs: { grow: "" } },
                                [
                                  _c("v-textarea", {
                                    staticClass: "goal-task",
                                    attrs: {
                                      outline: "",
                                      placeholder: _vm.$t(
                                        "app.tools.goal_setter.fields.task.placeholder"
                                      ),
                                      rows: "1",
                                      "auto-grow": "",
                                      "hide-details": ""
                                    },
                                    model: {
                                      value: task.description,
                                      callback: function($$v) {
                                        _vm.$set(task, "description", $$v)
                                      },
                                      expression: "task.description"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    }),
                    _vm.form.tasks.length >= 5
                      ? _c(
                          "v-flex",
                          { attrs: { xs12: "", "mt-2": "", "px-2": "" } },
                          [
                            _c("element-part-guide", {
                              attrs: { part: _vm.guideConfig }
                            })
                          ],
                          1
                        )
                      : _vm._e()
                  ]
                : _vm._e(),
              _vm.showDate || _vm.showTime
                ? [
                    _c(
                      "v-flex",
                      { attrs: { xs12: "", lg5: "", xl4: "", "mt-2": "" } },
                      [
                        _c(
                          "h4",
                          { staticClass: "starling-h4 StarlingDarkGrey--text" },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "app.tools.goal_setter.fields.startDate.title"
                                )
                              )
                            )
                          ]
                        )
                      ]
                    ),
                    _c(
                      "v-flex",
                      { attrs: { xs12: "", lg7: "", xl8: "", "mt-2": "" } },
                      [
                        _c(
                          "v-layout",
                          { attrs: { row: "", wrap: "", "gap-xs-2": "" } },
                          [
                            _vm.showDate
                              ? _c(
                                  "v-flex",
                                  {
                                    staticClass: "flex-date",
                                    attrs: { grow: "" }
                                  },
                                  [
                                    _c("v-text-field", {
                                      staticClass: "goal-start-date",
                                      class: {
                                        "form-modal-open": _vm.dateModalOpen
                                      },
                                      attrs: {
                                        outline: "",
                                        readonly: "",
                                        rules: _vm.rules.required,
                                        "prepend-icon": "mdi-calendar-blank",
                                        "hide-details": ""
                                      },
                                      on: {
                                        click: function($event) {
                                          $event.stopPropagation()
                                          _vm.dateModalOpen = true
                                        },
                                        "click:prepend": function($event) {
                                          $event.stopPropagation()
                                          _vm.dateModalOpen = true
                                        }
                                      },
                                      model: {
                                        value: _vm.formattedDate,
                                        callback: function($$v) {
                                          _vm.formattedDate = $$v
                                        },
                                        expression: "formattedDate"
                                      }
                                    })
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.showTime
                              ? _c(
                                  "v-flex",
                                  {
                                    staticClass: "flex-time",
                                    attrs: { grow: "" }
                                  },
                                  [
                                    _c("v-text-field", {
                                      staticClass: "goal-time",
                                      class: {
                                        "form-modal-open": _vm.timeModalOpen
                                      },
                                      attrs: {
                                        outline: "",
                                        readonly: "",
                                        rules: _vm.rules.required,
                                        "prepend-icon": "mdi-clock-outline",
                                        "hide-details": ""
                                      },
                                      on: {
                                        click: function($event) {
                                          $event.stopPropagation()
                                          _vm.timeModalOpen = true
                                        },
                                        "click:prepend": function($event) {
                                          $event.stopPropagation()
                                          _vm.timeModalOpen = true
                                        }
                                      },
                                      model: {
                                        value: _vm.formattedTime,
                                        callback: function($$v) {
                                          _vm.formattedTime = $$v
                                        },
                                        expression: "formattedTime"
                                      }
                                    })
                                  ],
                                  1
                                )
                              : _vm._e()
                          ],
                          1
                        ),
                        _vm.showDate
                          ? _c(
                              "v-dialog",
                              {
                                ref: "dateDialog",
                                attrs: {
                                  "return-value": _vm.dateField,
                                  persistent: "",
                                  lazy: "",
                                  "full-width": "",
                                  width: "290px",
                                  "content-class": "radius-15"
                                },
                                on: {
                                  "update:returnValue": function($event) {
                                    _vm.dateField = $event
                                  },
                                  "update:return-value": function($event) {
                                    _vm.dateField = $event
                                  }
                                },
                                model: {
                                  value: _vm.dateModalOpen,
                                  callback: function($$v) {
                                    _vm.dateModalOpen = $$v
                                  },
                                  expression: "dateModalOpen"
                                }
                              },
                              [
                                _c(
                                  "v-date-picker",
                                  {
                                    attrs: {
                                      color: "primary",
                                      locale: _vm.currentLanguage,
                                      min: _vm.minDate,
                                      scrollable: ""
                                    },
                                    model: {
                                      value: _vm.dateField,
                                      callback: function($$v) {
                                        _vm.dateField = $$v
                                      },
                                      expression: "dateField"
                                    }
                                  },
                                  [
                                    _c("v-spacer"),
                                    _c(
                                      "v-btn",
                                      {
                                        attrs: { flat: "" },
                                        on: {
                                          click: function($event) {
                                            _vm.dateModalOpen = false
                                          }
                                        }
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t("common.actions.cancel")
                                          )
                                        )
                                      ]
                                    ),
                                    _c(
                                      "primary-button",
                                      {
                                        on: {
                                          click: function($event) {
                                            return _vm.$refs.dateDialog.save(
                                              _vm.dateField
                                            )
                                          }
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(
                                              _vm.$t("common.actions.save")
                                            ) +
                                            "\n              "
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.showTime
                          ? _c(
                              "v-dialog",
                              {
                                ref: "timeDialog",
                                attrs: {
                                  "return-value": _vm.timeField,
                                  persistent: "",
                                  lazy: "",
                                  "full-width": "",
                                  width: "310px",
                                  "content-class": "radius-15"
                                },
                                on: {
                                  "update:returnValue": function($event) {
                                    _vm.timeField = $event
                                  },
                                  "update:return-value": function($event) {
                                    _vm.timeField = $event
                                  }
                                },
                                model: {
                                  value: _vm.timeModalOpen,
                                  callback: function($$v) {
                                    _vm.timeModalOpen = $$v
                                  },
                                  expression: "timeModalOpen"
                                }
                              },
                              [
                                _vm.timeModalOpen
                                  ? _c(
                                      "time-picker",
                                      {
                                        attrs: { format: _vm.timeFormat },
                                        model: {
                                          value: _vm.timeField,
                                          callback: function($$v) {
                                            _vm.timeField = $$v
                                          },
                                          expression: "timeField"
                                        }
                                      },
                                      [
                                        _c(
                                          "v-layout",
                                          {
                                            attrs: {
                                              "align-center": "",
                                              "justify-end": ""
                                            }
                                          },
                                          [
                                            _c(
                                              "v-flex",
                                              { attrs: { shrink: "" } },
                                              [
                                                _c(
                                                  "v-btn",
                                                  {
                                                    attrs: { flat: "" },
                                                    on: {
                                                      click: function($event) {
                                                        _vm.timeModalOpen = false
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "common.actions.cancel"
                                                        )
                                                      )
                                                    )
                                                  ]
                                                )
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-flex",
                                              { attrs: { shrink: "" } },
                                              [
                                                _c(
                                                  "primary-button",
                                                  {
                                                    attrs: {
                                                      id: "btn-time-picker-save"
                                                    },
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.$refs.timeDialog.save(
                                                          _vm.timeField
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                    " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "common.actions.save"
                                                          )
                                                        ) +
                                                        "\n                  "
                                                    )
                                                  ]
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  : _vm._e()
                              ],
                              1
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  ]
                : _vm._e(),
              _vm.showReminder
                ? [
                    _c(
                      "v-flex",
                      { attrs: { xs12: "", "mt-2": "" } },
                      [
                        _c(
                          "v-layout",
                          { attrs: { row: "", wrap: "" } },
                          [
                            _c("v-flex", { attrs: { grow: "" } }, [
                              _c(
                                "h4",
                                {
                                  staticClass:
                                    "starling-h4 StarlingDarkGrey--text"
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "app.tools.goal_setter.fields.reminder.title"
                                      )
                                    )
                                  )
                                ]
                              )
                            ]),
                            _c(
                              "v-flex",
                              {
                                attrs: {
                                  shrink: "",
                                  "align-self-center": "",
                                  "text-right": ""
                                }
                              },
                              [
                                _c("v-switch", {
                                  staticClass: "reminder-toggle pt-0",
                                  attrs: { "hide-details": "" },
                                  on: { change: _vm.onChangeReminderToggle },
                                  model: {
                                    value: _vm.sendReminderToggle,
                                    callback: function($$v) {
                                      _vm.sendReminderToggle = $$v
                                    },
                                    expression: "sendReminderToggle"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _vm.sendReminderToggle
                      ? _c(
                          "v-flex",
                          { attrs: { xs12: "", "mt-2": "" } },
                          [
                            _c(
                              "v-layout",
                              { attrs: { row: "" } },
                              [
                                _c(
                                  "v-flex",
                                  {
                                    attrs: {
                                      shrink: "",
                                      "offset-lg5": "",
                                      "offset-xl4": ""
                                    }
                                  },
                                  [
                                    _c("v-select", {
                                      staticClass:
                                        "reminder-interval mt-0 mr-2 pt-0",
                                      attrs: {
                                        outline: "",
                                        rules: _vm.rules.required,
                                        items: _vm.$t(
                                          "app.tools.goal_setter.fields.reminder.intervalOptions"
                                        ),
                                        "hide-details": ""
                                      },
                                      model: {
                                        value: _vm.form.reminderInterval,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.form,
                                            "reminderInterval",
                                            $$v
                                          )
                                        },
                                        expression: "form.reminderInterval"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "v-flex",
                                  { attrs: { "align-self-center": "" } },
                                  [
                                    _c("p", { staticClass: "starling-text" }, [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "app.tools.goal_setter.fields.reminder.label"
                                          )
                                        )
                                      )
                                    ])
                                  ]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.sendReminderToggle && _vm.showRepeat
                      ? _c(
                          "v-flex",
                          { attrs: { xs12: "", "mt-2": "" } },
                          [
                            _c(
                              "v-layout",
                              { attrs: { row: "", wrap: "" } },
                              [
                                _c("v-flex", { attrs: { grow: "" } }, [
                                  _c(
                                    "h4",
                                    {
                                      staticClass:
                                        "starling-h4 StarlingDarkGrey--text"
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "app.tools.goal_setter.fields.repeat.title"
                                          )
                                        )
                                      )
                                    ]
                                  )
                                ]),
                                _c(
                                  "v-flex",
                                  {
                                    attrs: {
                                      shrink: "",
                                      "align-self-center": "",
                                      "text-right": ""
                                    }
                                  },
                                  [
                                    _c("v-switch", {
                                      staticClass: "repeat-toggle pt-0",
                                      attrs: { "hide-details": "" },
                                      on: { change: _vm.onChangeRepeatToggle },
                                      model: {
                                        value: _vm.repeatToggle,
                                        callback: function($$v) {
                                          _vm.repeatToggle = $$v
                                        },
                                        expression: "repeatToggle"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.repeatToggle && _vm.showRepeat
                      ? [
                          _c(
                            "v-flex",
                            { attrs: { xs12: "", "mt-2": "" } },
                            [
                              _c(
                                "v-layout",
                                { attrs: { row: "" } },
                                [
                                  _c(
                                    "v-flex",
                                    {
                                      attrs: {
                                        "offset-lg5": "",
                                        "offset-xl4": "",
                                        shrink: "",
                                        "align-self-center": ""
                                      }
                                    },
                                    [
                                      _c(
                                        "p",
                                        { staticClass: "starling-text mr-2" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                "app.tools.goal_setter.fields.repeat.label"
                                              )
                                            )
                                          )
                                        ]
                                      )
                                    ]
                                  ),
                                  _c(
                                    "v-flex",
                                    [
                                      _c("v-select", {
                                        staticClass:
                                          "repeat-frequency mt-0 pt-0",
                                        attrs: {
                                          outline: "",
                                          rules: _vm.rules.required,
                                          items: _vm.$t(
                                            "app.tools.goal_setter.fields.repeat.frequency"
                                          ),
                                          "hide-details": ""
                                        },
                                        model: {
                                          value: _vm.repeatFrequency,
                                          callback: function($$v) {
                                            _vm.repeatFrequency = $$v
                                          },
                                          expression: "repeatFrequency"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm.repeatFrequency === "WEEK"
                            ? _c(
                                "v-flex",
                                {
                                  attrs: {
                                    xs12: "",
                                    lg8: "",
                                    "offset-lg5": "",
                                    "offset-xl4": "",
                                    "mt-2": ""
                                  }
                                },
                                [
                                  _c(
                                    "v-btn-toggle",
                                    {
                                      staticClass:
                                        "layout justify-space-between mx-auto px-2 weekday-options",
                                      attrs: { multiple: "" },
                                      model: {
                                        value: _vm.repeatFrequencyDays,
                                        callback: function($$v) {
                                          _vm.repeatFrequencyDays = $$v
                                        },
                                        expression: "repeatFrequencyDays"
                                      }
                                    },
                                    _vm._l(
                                      _vm.$t(
                                        "app.tools.goal_setter.fields.repeat.weekDays"
                                      ),
                                      function(weekDay) {
                                        return _c(
                                          "v-btn",
                                          {
                                            key: weekDay.value,
                                            staticClass: "shrink",
                                            attrs: {
                                              flat: "",
                                              icon: "",
                                              "active-class":
                                                "weekday-selected",
                                              value: weekDay.value
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n                " +
                                                _vm._s(weekDay.text) +
                                                "\n              "
                                            )
                                          ]
                                        )
                                      }
                                    ),
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e()
                        ]
                      : _vm._e()
                  ]
                : _vm._e(),
              _vm.showPin
                ? _c(
                    "v-flex",
                    { attrs: { xs12: "", "mt-2": "" } },
                    [
                      _c(
                        "v-layout",
                        { attrs: { row: "", wrap: "" } },
                        [
                          _c("v-flex", { attrs: { grow: "" } }, [
                            _c(
                              "h4",
                              {
                                staticClass:
                                  "starling-h4 StarlingDarkGrey--text"
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "app.tools.goal_setter.fields.pinned.title"
                                    )
                                  )
                                )
                              ]
                            )
                          ]),
                          _c(
                            "v-flex",
                            {
                              attrs: {
                                shrink: "",
                                "align-self-center": "",
                                "text-right": ""
                              }
                            },
                            [
                              _c("v-switch", {
                                staticClass: "pinned-toggle pt-0",
                                attrs: { "hide-details": "" },
                                model: {
                                  value: _vm.form.pinned,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "pinned", $$v)
                                  },
                                  expression: "form.pinned"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "v-flex",
                { attrs: { xs12: "", "mt-3": "" } },
                [
                  _c(
                    "v-layout",
                    {
                      attrs: {
                        row: "",
                        wrap: "",
                        "justify-center": "",
                        "align-center": ""
                      }
                    },
                    [
                      _c(
                        "v-flex",
                        { attrs: { xs12: "", "text-center": "" } },
                        [
                          _c("action-buttons", {
                            attrs: {
                              primary: {
                                label: _vm.inline
                                  ? _vm.$t("app.tools.goal_setter.actions.save")
                                  : _vm.$t("common.actions.save"),
                                disabled: !_vm.valid,
                                loading: _vm.loading
                              },
                              secondary: _vm.cancellable
                                ? {
                                    label: _vm.$t("common.actions.cancel"),
                                    disabled: _vm.loading
                                  }
                                : undefined
                            },
                            on: {
                              "primary-clicked": _vm.submitAction,
                              "secondary-clicked": _vm.onCancel
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            2
          )
        ],
        1
      ),
      _c("custom-confirmation-modal", {
        attrs: {
          display: _vm.showGoalReminderModal,
          title: _vm.$t("app.tools.goal_setter.fields.reminder.madal_title"),
          description: _vm.$t(
            "app.tools.goal_setter.fields.reminder.modal_description"
          ),
          primaryConfirmLabel: _vm.$t("common.actions.continue"),
          secondaryCancelLabel: _vm.$t("common.actions.cancel"),
          primaryConfirmDisabled: !_vm.emailReminder && !_vm.pushReminder,
          primaryConfirmLoading: _vm.loading
        },
        on: {
          "cancel-event": _vm.cancelGoalReminderModal,
          "confirm-event": _vm.saveGoalReminder
        },
        scopedSlots: _vm._u([
          {
            key: "afterDescription",
            fn: function() {
              return [
                _c(
                  "v-flex",
                  { attrs: { xs12: "", "mt-3": "" } },
                  [
                    _c(
                      "v-layout",
                      { attrs: { row: "" } },
                      [
                        _c("v-flex", { attrs: { xs6: "" } }, [
                          _c(
                            "p",
                            {
                              staticClass:
                                "starling-body StarlingPrimary2--text"
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.$t("app.profile.communication.email")
                                )
                              )
                            ]
                          )
                        ]),
                        _c(
                          "v-flex",
                          { attrs: { xs6: "" } },
                          [
                            _c("v-switch", {
                              staticClass: "ma-0 justify-end",
                              attrs: { "hide-details": "" },
                              model: {
                                value: _vm.emailReminder,
                                callback: function($$v) {
                                  _vm.emailReminder = $$v
                                },
                                expression: "emailReminder"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "v-flex",
                  { attrs: { xs12: "", "mt-2": "" } },
                  [
                    _c(
                      "v-layout",
                      { attrs: { row: "" } },
                      [
                        _c("v-flex", { attrs: { xs6: "" } }, [
                          _c(
                            "p",
                            {
                              staticClass:
                                "starling-body StarlingPrimary2--text"
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.$t("app.profile.communication.app"))
                              )
                            ]
                          )
                        ]),
                        _c(
                          "v-flex",
                          { attrs: { xs6: "" } },
                          [
                            _c("v-switch", {
                              staticClass: "ma-0 justify-end",
                              attrs: { "hide-details": "" },
                              model: {
                                value: _vm.pushReminder,
                                callback: function($$v) {
                                  _vm.pushReminder = $$v
                                },
                                expression: "pushReminder"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }