var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "overflow-hidden pa-2" },
    [
      _c(
        "v-card-text",
        { staticClass: "pa-0" },
        [
          _c(
            "v-layout",
            {
              staticClass: "time-picker",
              attrs: {
                "align-center": "",
                "justify-center": "",
                "gap-xs-1": ""
              }
            },
            [
              _c(
                "v-flex",
                { attrs: { shrink: "" } },
                [
                  _c(
                    "v-layout",
                    {
                      staticClass: "time-selector",
                      attrs: {
                        column: "",
                        "align-center": "",
                        "justify-center": ""
                      }
                    },
                    [
                      _c(
                        "v-flex",
                        {
                          staticClass: "selector-up",
                          attrs: { shrink: "", "d-flex": "" },
                          on: { click: _vm.onHourUpClick }
                        },
                        [_c("v-icon", [_vm._v("keyboard_arrow_up")])],
                        1
                      ),
                      _c(
                        "v-flex",
                        {
                          staticClass: "selector-value",
                          attrs: { grow: "", "d-flex": "", "align-center": "" }
                        },
                        [
                          _c("span", { staticClass: "value-text" }, [
                            _vm._v(_vm._s(_vm._f("leadingZeros")(_vm.hour, 2)))
                          ])
                        ]
                      ),
                      _c(
                        "v-flex",
                        {
                          staticClass: "selector-down",
                          attrs: { shrink: "", "d-flex": "" },
                          on: { click: _vm.onHourDownClick }
                        },
                        [_c("v-icon", [_vm._v("keyboard_arrow_down")])],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c("v-flex", { attrs: { shrink: "" } }, [
                _c("p", { staticClass: "separator-text ma-0 pb-1" }, [
                  _vm._v(":")
                ])
              ]),
              _c(
                "v-flex",
                { attrs: { shrink: "" } },
                [
                  _c(
                    "v-layout",
                    {
                      staticClass: "time-selector",
                      attrs: {
                        column: "",
                        "align-center": "",
                        "justify-center": ""
                      }
                    },
                    [
                      _c(
                        "v-flex",
                        {
                          staticClass: "selector-up",
                          attrs: { shrink: "", "d-flex": "" },
                          on: { click: _vm.onMinuteUpClick }
                        },
                        [_c("v-icon", [_vm._v("keyboard_arrow_up")])],
                        1
                      ),
                      _c(
                        "v-flex",
                        {
                          staticClass: "selector-value",
                          attrs: { grow: "", "d-flex": "", "align-center": "" }
                        },
                        [
                          _c("span", { staticClass: "value-text" }, [
                            _vm._v(
                              _vm._s(_vm._f("leadingZeros")(_vm.minute, 2))
                            )
                          ])
                        ]
                      ),
                      _c(
                        "v-flex",
                        {
                          staticClass: "selector-down",
                          attrs: { shrink: "", "d-flex": "" },
                          on: { click: _vm.onMinuteDownClick }
                        },
                        [_c("v-icon", [_vm._v("keyboard_arrow_down")])],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm.showAmPmSelector
                ? _c(
                    "v-flex",
                    { attrs: { shrink: "" } },
                    [
                      _c(
                        "v-layout",
                        {
                          staticClass: "time-selector",
                          attrs: {
                            column: "",
                            "align-center": "",
                            "justify-center": ""
                          }
                        },
                        [
                          _c(
                            "v-flex",
                            {
                              staticClass: "selector-up",
                              attrs: { shrink: "", "d-flex": "" },
                              on: { click: _vm.onAmPmClick }
                            },
                            [_c("v-icon", [_vm._v("keyboard_arrow_up")])],
                            1
                          ),
                          _c(
                            "v-flex",
                            {
                              staticClass: "selector-value",
                              attrs: {
                                grow: "",
                                "d-flex": "",
                                "align-center": ""
                              }
                            },
                            [
                              _c("span", { staticClass: "value-text" }, [
                                _vm._v(_vm._s(_vm.amPm))
                              ])
                            ]
                          ),
                          _c(
                            "v-flex",
                            {
                              staticClass: "selector-down",
                              attrs: { shrink: "", "d-flex": "" },
                              on: { click: _vm.onAmPmClick }
                            },
                            [_c("v-icon", [_vm._v("keyboard_arrow_down")])],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _c("v-card-actions", { staticClass: "pa-0 pt-2" }, [_vm._t("default")], 2)
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }