


























































import Vue from 'vue';
import moment from 'moment';

const TIME_AMPM = 'hh:mm A';
const TIME_24HS = 'HH:mm';

export default Vue.extend({
  name: 'time-picker',
  props: {
    value: {
      type: String,
      required: false,
    },
    format: {
      type: String,
      required: false,
      default: 'ampm',
    },
    minuteInterval: {
      type: Number,
      required: false,
      default: 30,
    },
  },
  data() {
    const initialTime = this.value ? moment(this.value, this.format === 'ampm' ? TIME_AMPM : TIME_24HS) : moment();
    initialTime.seconds(0);

    if (initialTime.minutes() % this.minuteInterval != 0) {
      const interval = Math.ceil(initialTime.minutes() / this.minuteInterval);
      initialTime.minutes(interval * this.minuteInterval);
    }

    return {
      hour: parseInt(initialTime.format(this.format === 'ampm' ? 'h' : 'H')),
      minute: initialTime.minutes(),
      amPm: initialTime.format('A'),
    };
  },
  computed: {
    showAmPmSelector(): boolean {
      return this.format === 'ampm';
    },
    internalValue(): string {
      const formattedHour = this.$options.filters?.leadingZeros(this.hour, 2);
      const formattedMinute = this.$options.filters?.leadingZeros(this.minute, 2);
      if (this.showAmPmSelector) {
        return moment(`${formattedHour}:${formattedMinute} ${this.amPm}`, TIME_AMPM).format(TIME_24HS);
      }
      return `${formattedHour}:${formattedMinute}`;
    },
    minHour(): number {
      return this.showAmPmSelector ? 1 : 0;
    },
    maxHour(): number {
      return this.showAmPmSelector ? 12 : 23;
    },
  },
  watch: {
    internalValue: {
      immediate: true,
      handler(newVal: string) {
        this.$emit('input', newVal);
      },
    },
  },
  methods: {
    onHourUpClick() {
      const newTime = this.hour + 1;
      this.hour = newTime > this.maxHour ? this.minHour : newTime;
    },
    onHourDownClick() {
      const newTime = this.hour - 1;
      this.hour = newTime < this.minHour ? this.maxHour : newTime;
    },
    onMinuteUpClick() {
      let newTime = this.minute + this.minuteInterval;
      while (newTime >= 60) newTime = newTime - 60;
      this.minute = newTime < 0 ? 0 : newTime;
    },
    onMinuteDownClick() {
      let newTime = this.minute - this.minuteInterval;
      while (newTime < 0) newTime = newTime + 60;
      this.minute = newTime > 59 ? 0 : newTime;
    },
    onAmPmClick() {
      this.amPm = this.amPm === 'PM' ? 'AM' : 'PM';
    },
  },
});
