
































































































































































































































































































import Vue from 'vue';
import { format, parse } from 'date-fns';
import { GoalRepeatFrequencyEnum, GoalStatusEnum, IGoalSetter } from '@/scripts/store/modules/tools/goals/types';
import { IStyleContentGuide, StyleTypeEnum } from '@/scripts/store/modules/therapy/types';
import store from '@/scripts/store';
import actionButtons from '@/views/components/navigation/action-buttons.vue';
import ElementPartGuide from '@/views/products/components/elements-parts/element-part-guide.vue';
import PrimaryButton from '@/views/components/button/primary-button.vue';
import TimePicker from '@/views/components/utilities/time-picker.vue';
import moment from 'moment';
import customConfirmationModal from '@/views/components/utilities/custom-confirmation-modal.vue';

const DATE_FORMAT = 'yyyy-MM-dd';
const TIME_FORMAT = 'HH:mm';

export default Vue.extend({
  name: 'goal-form',
  components: { actionButtons, ElementPartGuide, PrimaryButton, TimePicker, customConfirmationModal },
  props: {
    goal: {
      type: Object as () => IGoalSetter,
      required: true,
    },
    cancellable: {
      type: Boolean,
      required: false,
      default: false,
    },
    inline: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
      valid: false,
      dateModalOpen: false,
      timeModalOpen: false,
      form: {} as IGoalSetter,
      dateField: undefined as string | undefined,
      timeField: undefined as string | undefined,
      sendReminderToggle: false,
      repeatToggle: false,
      rules: {
        required: [ (v: string) => !!v || this.$t('common.errors.required') ],
        title: [
          (v: string) => !!v || this.$t('common.errors.required'),
          (v: string) => !v || v.length <= 255 || this.$t('common.errors.max255'),
        ],
      },
      guideConfig: {
        key: StyleTypeEnum.GUIDE,
        expanded: true,
        content: this.$t('app.tools.goal_setter.fields.task.guideContent'),
      } as IStyleContentGuide,
      repeatFrequency: undefined as string | undefined,
      repeatFrequencyDays: [] as string[],
      showGoalReminderModal: false,
      tempSendReminderToggle: false,
      emailReminder: true,
      pushReminder: true,
    };
  },
  computed: {
    minDate() {
      return moment().format('YYYY-MM-DD');
    },
    currentLanguage() {
      return store.getters.currentLanguage;
    },
    currentLocale() {
      return store.getters.getCurrentLocale;
    },
    abbreviatedDateFormat() {
      return store.getters.getAbbreviatedDateFormat;
    },
    timeFormat() {
      return store.getters.getHourFormat;
    },
    formattedDate: {
      get(): string | null {
        return this.dateField ? format(parse(this.dateField, DATE_FORMAT, new Date()), this.abbreviatedDateFormat, { locale: this.currentLocale }) : null;
      },
      set(newValue: string) {
      },
    },
    formattedTime: {
      get(): string | null {
        return this.timeField ? format(parse(this.timeField, TIME_FORMAT, new Date()), this.currentLocale.formatLong?.time({ width: 'short' }), { locale: this.currentLocale }) : null;
      },
      set(newValue: string) {
      },
    },
    showTasks(): boolean {
      return !this.goal.visibleFields || this.goal.visibleFields.some(field => field === 'tasks' || field === 'description');
    },
    showDate(): boolean {
      return !this.goal.visibleFields || this.goal.visibleFields.some(field => field === 'date');
    },
    showTime(): boolean {
      return !this.goal.visibleFields || this.goal.visibleFields.some(field => field === 'time');
    },
    showReminder(): boolean {
      return !this.goal.visibleFields || this.goal.visibleFields.some(field => field === 'reminder');
    },
    showRepeat(): boolean {
      return !this.goal.visibleFields || this.goal.visibleFields.some(field => field === 'repeat');
    },
    showPin(): boolean {
      return this.goal.status !== GoalStatusEnum.COMPLETED && (!this.goal.visibleFields || this.goal.visibleFields.some(field => field === 'pin'));
    },
    user(): any {
      return this.$store.getters.user;
    },
  },
  watch: {
    goal: {
      immediate: true,
      handler(newValue) {
        this.setGoal(newValue);
      },
    },
  },
  mounted () {
    this.setGoal(this.goal);
  },
  methods: {
    setGoal(goal: IGoalSetter) {
      this.form = {
        id: goal.id,
        title: goal.title,
        status: goal.status,
        startDate: goal.startDate,
        completedPercentage: goal.completedPercentage || 0,
        reminderInterval: goal.reminderInterval,
        repeatFrequency: goal.repeatFrequency || [],
        pinned: goal.pinned,
        tasks: goal.tasks && goal.tasks.length ? goal.tasks : [ { id: undefined, description: '', completed: false } ],
        uen: goal.uen,
      } as IGoalSetter;

      let dateField;
      let timeField;

      if (goal.startDate) {
        const startDate = new Date(goal.startDate);
        const dueTime = startDate.getTime();
        dateField = format(startDate, 'y-MM-dd');
        timeField = format(dueTime, 'H') + ':' + format(dueTime, 'mm');
        timeField = timeField !== '23:59' ? timeField : '';
      }

      this.dateField = dateField;
      this.timeField = timeField;

      this.sendReminderToggle = !!goal.reminderInterval;
      this.repeatToggle = false;
      this.repeatFrequency = undefined;
      this.repeatFrequencyDays = [];

      if (goal.repeatFrequency && goal.repeatFrequency.length) {
        this.repeatToggle = true;
        if (goal.repeatFrequency[0] === GoalRepeatFrequencyEnum.DAILY) {
          this.repeatFrequency = 'DAY';
        } else {
          this.repeatFrequency = 'WEEK';
          this.repeatFrequencyDays = [ ...goal.repeatFrequency ];
        }
      }

      if (this.$refs && this.$refs.form) {
        const formElement: HTMLFormElement = this.$refs.form as HTMLFormElement;
        formElement.resetValidation();
      }
    },
    submitAction() {
      const formElement: HTMLFormElement = this.$refs.form as HTMLFormElement;
      if (formElement.validate()) {
        const goal = Object.assign({}, this.form) as IGoalSetter;
        this.$log.debug('Submitting goal', goal);

        const date = this.dateField?.replace(/-/g, '/');
        const time = `${this.timeField}:00`;
        goal.startDate = new Date(`${date} ${time}`);

        if (this.repeatToggle) {
          if (this.repeatFrequency === 'DAY') {
            goal.repeatFrequency = [ GoalRepeatFrequencyEnum.DAILY ];
          } else {
            goal.repeatFrequency = this.repeatFrequencyDays.map(day => GoalRepeatFrequencyEnum[day as keyof typeof GoalRepeatFrequencyEnum]);
          }
        } else {
          goal.repeatFrequency = undefined;
        }

        goal.tasks = goal.tasks.filter(task => !!task.description && !!task.description.trim());

        this.$log.debug('Updated goal to submit', goal);

        if (goal.id) {
          this.updateGoal(goal);
        } else {
          this.createGoal(goal);
        }
      }
    },
    createGoal(goal: IGoalSetter) {
      this.loading = true;
      this.$store.dispatch('goals/createGoal', goal)
        .then(res => {
          this.$emit('update', res);
        }).catch(() => {
          this.$store.commit('setNotification', { text: this.$t('app.tools.goal_setter.notifications.errored') });
        }).finally(() => {
          this.loading = false;
        });
    },
    updateGoal(goal: IGoalSetter) {
      this.loading = true;
      this.$store.dispatch('goals/editGoal', goal)
        .then(res => {
          this.$emit('update', res);
        }).catch(() => {
          this.$store.commit('setNotification', { text: this.$t('app.tools.goal_setter.notifications.errored') });
        }).finally(() => {
          this.loading = false;
        });
    },
    onCancel() {
      this.$emit('cancel');
    },
    onChangeReminderToggle(newValue: boolean) {
      this.tempSendReminderToggle = this.sendReminderToggle;
      if (newValue && !this.user.notifications.some((notification: any) => notification.goalReminder)) {
        this.showGoalReminderModal = true;
        return;
      }
      if (!newValue) {
        this.form.reminderInterval = undefined;
        this.repeatToggle = false;
        this.onChangeRepeatToggle(false);
        return;
      }
      const intervalFirstOption = this.$t('app.tools.goal_setter.fields.reminder.intervalOptions') as any as { value: number, text: string }[];
      this.form.reminderInterval = intervalFirstOption[0].value;
    },
    onChangeRepeatToggle(newValue: boolean) {
      this.repeatFrequencyDays = [];
      if (!newValue) {
        this.repeatFrequency = undefined;
        return;
      }
      const frequencyFirstOption = this.$t('app.tools.goal_setter.fields.repeat.frequency') as any as { value: string, text: string }[];
      this.repeatFrequency = frequencyFirstOption[0].value;
    },
    onAddTask() {
      if (this.form.tasks.length >= 5) return;
      this.form.tasks.push({
        id: undefined,
        description: '',
        completed: false,
      });
    },
    onRemoveTask(taskIdx: number) {
      if (this.form.tasks.length <= 1) return;
      this.form.tasks.splice(taskIdx, 1);
    },
    updateCommunications() {
      if (this.loading) return;
      this.loading = true;
      this.$store.dispatch('editUser', {}).then(() => {
        this.$store.commit('setNotification', { text: this.$t('app.profile.notifications.updated') });
      }).finally(() => {
        this.onChangeReminderToggle(true);
        this.showGoalReminderModal = false;
        this.loading = false;
      });
    },
    saveGoalReminder() {
      if (!this.emailReminder && !this.pushReminder) {
        return;
      }
      this.user.notifications.find((notification: any) => notification.type === 'EMAIL').goalReminder = this.emailReminder;
      this.user.notifications.find((notification: any) => notification.type === 'PUSH').goalReminder = this.pushReminder;
      this.updateCommunications();
    },
    cancelGoalReminderModal() {
      this.sendReminderToggle = !this.tempSendReminderToggle;
      this.showGoalReminderModal = false;
    },
  },
});
